<template>
  <div class="center">
    <AppDropdownSelect :options="moreOptions" :autoHide=true :showArrow=false :selectable=false placement="bottom"
      popoverClass="more-custom-popover" @input="onItemClick">
      <template slot="trigger">
        <span class="more-icon"><i class="fa fa-ellipsis-v"></i></span>
      </template>
    </AppDropdownSelect>
  </div>
</template>
<script>
import AppDropdownSelect from '@/components/common/app-dropdown-select/AppDropdownSelect';
export default {
  components: {
    AppDropdownSelect,
  },

  props: ['row', 'nested', 'xprops'],

  data() {

    return {
      moreOptions: [
      ],
    }
  },

  mounted() {
    if (this.row.opt_out) {
      this.moreOptions.unshift({ icon: 'fa fa fa-sign-in', label: 'Opt In', value: 'opt'  })
    } else {
      this.moreOptions.unshift({ icon: 'fa fa fa-sign-out text-danger', label: 'Opt Out', value: 'opt'  })
    }
  },

  methods: {
    onItemClick(item) {
      switch (item.value) {
        case 'opt':
          this.onToggleOptOut()
          break;
      }
    },

    onToggleOptOut() {
      this.xprops.eventbus.$emit('onToggleOptOut', this.row)
    },
  },
}
</script>

<style scoped lang="scss">
</style>
